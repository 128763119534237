import { BusinessStoreResponse } from '@viewlio/types/api/juulio'
import { decamelizeKeys } from 'utils/caseConversion'
import { fetchFromApi } from './fetchFromApi'

export const fetchBusinessStore = async (): Promise<BusinessStoreResponse> =>
  fetchFromApi({
    autoCamelizeKeys: false,
    method: 'GET',
    relativePath: '/api/v1/stores',
    throwError: true,
  })

export const signInBusinessUser = async (body) => {
  const params = { spreeUser: body }

  return fetchFromApi({
    body: decamelizeKeys(params),
    method: 'POST',
    relativePath: '/api/v1/signin',
    throwError: true,
  })
}

export const acceptBusinessTermsAndConditions = async () =>
  fetchFromApi({
    method: 'PUT',
    relativePath: '/api/v1/terms_and_conditions/check',
    throwError: true,
  })
